import {GET_BANK_LIST, GET_BANK_VALUE_LIST} from 'shared/constants/ActionTypes';

const initialState = {
  bankList: [],
  foundCount: 0,
  noRecordsFound: false,
  bankValueList: [],
};

const purchaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANK_LIST:
      return {
        ...state,
        bankList: action.payload.data,
        foundCount: action.payload.dataInfo.foundCount,
        noRecordsFound: action.payload.noRecordsFound,
      };

    case GET_BANK_VALUE_LIST:
      return {
        ...state,
        bankValueList: JSON.parse(action.payload),
      };

    default:
      return state;
  }
};
export default purchaseReducer;
