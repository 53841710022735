import {
  GET_PURCHASE_LIST,
  GET_PURCHASE_DETAIL,
  UPDATE_PURCHASE_DETAIL,
  CLEAR_PURCHASE_DETAIL,
  GET_PURCHASE_VALUE_LIST,
  UPDATE_PURCHASE_LIST_ITEM,
  ADD_PURCHASE_LIST_ITEM,
  DELETE_PURCHASE_LIST_ITEM,
  UPDATE_FULFILLMENT_PORTAL_DATA,
} from 'shared/constants/ActionTypes';

const initialState = {
  purchaseList: [],
  scriptData: [],
  foundCount: 0,
  noRecordsFound: false,
  purchaseValueList: [],
};

const purchaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PURCHASE_LIST:
      return {
        ...state,
        purchaseList: action.payload.data,
        foundCount: action.payload.dataInfo.foundCount,
        noRecordsFound: action.payload.noRecordsFound,
        scriptData: action.payload.noRecordsFound
          ? []
          : JSON.parse(action.payload['scriptResult.presort']),
      };

    case GET_PURCHASE_VALUE_LIST:
      return {
        ...state,
        purchaseValueList: JSON.parse(action.payload),
      };

    case GET_PURCHASE_DETAIL:
      return {
        ...state,
        purchaseDetail: action.payload.data[0],
      };

    case CLEAR_PURCHASE_DETAIL: {
      state.purchaseDetail = {
        fieldData: {},
        portalData: {
          Orders: [],
          Doklady_partneri: [],
          Doklady_plnenia: [],
          Doklady_sort_history: [],
          Doklady_objednavka: [],
        },
      };

      return {
        ...state,
      };
    }

    case UPDATE_PURCHASE_DETAIL: {
      let item = action.payload.response.data[0];
      state.purchaseDetail = item;

      return {
        ...state,
      };
    }

    case UPDATE_FULFILLMENT_PORTAL_DATA: {
      let item = action.payload.data[0].portalData['Doklady_plnenia'];
      let portalData = state.purchaseDetail.portalData;
      let newPortaldata = {...portalData, Doklady_plnenia: item};
      const newData = {...state.purchaseDetail, portalData: newPortaldata};
      state.purchaseDetail = newData;

      return {
        ...state,
      };
    }

    case UPDATE_PURCHASE_LIST_ITEM: {
      let item = action.payload.listItem.response.data[0];
      let recordId = action.payload.listItem.response.data[0].recordId;
      let originalAmount = state.purchaseList.find(
        (e) => recordId == e.recordId,
      ).fieldData.Doklad_s_danou;

      const updatedlist = state.purchaseList.map((e) =>
        recordId == e.recordId ? item : e,
      );

      state.purchaseList = updatedlist;

      //scriptDataUpdate
      let newAmount = item.fieldData.Doklad_s_danou - originalAmount;
      let data = state.scriptData;
      let newFooterInfo = {
        neuhradeneTotal: data.footerInfo.neuhradeneTotal + newAmount,
        sumadokladuTotal: data.footerInfo.sumadokladuTotal + newAmount,
      };
      let newScriptData = {...state.scriptData, footerInfo: newFooterInfo};
      state.scriptData = newScriptData;

      //purchaseDetailUpdate
      let detail = action.payload.detail.response.data[0];
      state.purchaseDetail = detail;

      return {
        ...state,
      };
    }

    case ADD_PURCHASE_LIST_ITEM: {
      let item = action.payload.listItem.response.data[0];
      let obj = [item];
      state.purchaseList.map((e) => obj.push(e));
      state.purchaseList = obj;
      state.foundCount = state.foundCount + 1;

      //scriptDataAdd

      let newAmount = item.fieldData.Doklad_s_danou;
      let recordId = action.payload.recordId;
      let data = state.scriptData;

      let newScriptData = {};
      if (state.foundCount > 1) {
        let newFooterInfo = {
          neuhradeneTotal: data.footerInfo.neuhradeneTotal + newAmount,
          sumadokladuTotal: data.footerInfo.sumadokladuTotal + newAmount,
        };
        newScriptData = {...state.scriptData, footerInfo: newFooterInfo};
        newScriptData.idList.push(Number(recordId));
      } else {
        newScriptData = {
          footerInfo: {neuhradeneTotal: newAmount, sumadokladuTotal: newAmount},
          idList: [Number(recordId)],
        };
      }

      state.scriptData = newScriptData;

      //purchaseDetailUpdate
      let detail = action.payload.detail.response.data[0];
      state.purchaseDetail = detail;

      return {
        ...state,
      };
    }

    case DELETE_PURCHASE_LIST_ITEM: {
      let recordId = action.payload.id;
      let amount = action.payload.amount;
      state.foundCount = state.foundCount - 1;

      const updatedlist = state.purchaseList.filter((e) => {
        if (recordId != e.recordId) return e;
      });

      state.purchaseList = updatedlist;

      //scriptDataRemove

      let data = state.scriptData;

      let newScriptData = {};
      if (state.foundCount > 0) {
        let newFooterInfo = {
          neuhradeneTotal: data.footerInfo.neuhradeneTotal - amount,
          sumadokladuTotal: data.footerInfo.sumadokladuTotal - amount,
        };
        newScriptData = {...state.scriptData, footerInfo: newFooterInfo};
        newScriptData.idList.filter((e) => {
          if (recordId != e) return e;
        });
      } else {
        newScriptData = {
          footerInfo: {neuhradeneTotal: 0, sumadokladuTotal: 0},
          idList: [],
        };
      }

      state.scriptData = newScriptData;

      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
export default purchaseReducer;
